import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../utils';
import styles from './index.less';
import type { Store } from './store';

export const SelectUserModal = observer((props: { store: Store; }) => {
  const {
    visible,
    toogleModal,
    bindMobile,
  } = props.store;
  return (
    <Modal
      centered
      className={styles.selectUserModal}
      destroyOnClose
      footer={null}
      onCancel={() => {
        toogleModal(false);
      }}
      open={visible}
      title=" "
      width={385}
    >
      <div className={styles.wechatUserInfoWrap}>
        <p
          className={styles.infoTip}
          style={{ marginBottom: 0 }}
        >
          根据您的
        </p>
        <p className={styles.infoTip}>
          手机号，找到以下账号
        </p>
        <p
          className={`${styles.fs14} ${styles.fs400}`}
          style={{
            color: '#6D6E78',
            textAlign: 'left',
          }}
        >
          请确认您要绑定的账号
        </p>
        <BindUserInfo store={props.store}/>
        <Button
          className={styles.bindBtn}
          danger
          onClick={bindMobile}
          type="primary"
        >
          立即绑定
        </Button>
      </div>
    </Modal>
  );
});

const BindUserInfo = observer((props: { store: Store; }) => {
  const {
    bindInfo,
    selectUser,
    selectUserId,
  } = props.store;
  return (
    <div className={styles.content}>
      {
        bindInfo?.map((item) => {
          const {
            userId,
            username,
            mobile,
            showName,
            pic,
          } = item;
          return (
            <div
              className={`${styles.item} ${selectUserId === userId ? styles.selected : ''}`}
              key={userId}
              onClick={() => {
                selectUser(userId);
              }}
            >
              <img
                alt=""
                className={styles.avatar}
                src={pic || `${PUBLIC_IMAGE_URL}avatar.png`}
              />
              <div className={styles.userinfo}>
                <div
                  className={`${styles.fs14} ${styles.fw400} ${styles.colorHead} ${styles.showname}`}
                  title={showName}
                >
                  {showName}
                </div>
                <div
                  className={`${styles.fs14} ${styles.fw400} ${styles.usernameWrap}`}
                  style={{ color: '#999BA4' }}
                >

                  <div
                    className={styles.username}
                    title={username}
                  >
                    <i className="icon-yh01"/>
                    {username}
                  </div>
                  <div className={styles.username}>
                    <i
                      className="icon-phone"
                    />
                    {mobile}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
});
