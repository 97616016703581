import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { api } from '../../../utils';
import type parentStore from './store';

export default class Store {
  constructor(parent) {
    this.parent = parent;
  }

  @observable public parent: parentStore;

  @observable public visible = false;

  @observable public loading = false;

  @observable public protalHtml = '';

  @observable public title = '';

  // 打开实名认证信息弹窗
  @action public showModal = (id: number, title: string): void => {
    this.visible = true;
    this.loading = true;
    request<BaseData<string>>({
      url: `${api.getProtocal}`,
      params: { id },
    }).then((res) => {
      this.protalHtml = res.data;
      this.title = title;
    })
      .catch(() => {
        this.protalHtml = '';
      })
      .finally(() => {
        this.loading = false;
      });
  };

  @action public closeModal = (): void => {
    this.visible = false;
  };
}
