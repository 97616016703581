import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { phoneReg } from 'egenie-common';
import type { IObj } from 'egenie-utils';
import { request, SlideVerify } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { api } from '../../utils';
import ProtalModalStore from '../components/protalModal/store';
import type { INeedBindUser, UserInfo } from './interface';

const commonParams = {
  appType: 'pc',
  sysType: 'pc_shj',
};

export class Store {
  constructor() {
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const accessCode = search.get('accessCode');
    this.accessCode = accessCode;
    this.getWechatUserInfo();
  }

  public loginRef = React.createRef<FormInstance>();

  @observable public protalModalStore = new ProtalModalStore(this);

  @observable public userInfo: UserInfo = {
    nickname: '',
    pic: '',
  };

  @observable public bindInfo: INeedBindUser[] = [];

  @observable public slideVerify = null;

  @observable public tabKey = 'mobileBind'; // mobileBind: 手机绑定 ｜ accountBind: 帐号登录

  @observable public isSendCode = false;

  @observable public countDown = 60;

  @observable public timer;

  @observable public visible = false;

  @observable public accessCode = '';

  @observable public selectUserId = null;

  @action public getWechatUserInfo = (): void => {
    request({
      url: api.getWechatUserInfo,
      params: { accessCode: this.accessCode },
    })
      .then((res: BaseData<UserInfo>) => {
        Object.assign(this.userInfo, { ...(res?.data || {}) });
      });
  };

  @action public changeTab = (key: string): void => {
    this.tabKey = key;
    this.loginRef.current.setFieldsValue({
      wechatUsername: null,
      wechatPassword: null,
      code: '',
    });
  };

  // 绑定
  @action public bindWechat = (): void => {
    const url = this.tabKey === 'mobileBind' ? api.wechatBindMobile : api.wechatBindUsername;
    this.loginRef.current?.validateFields()
      .then((data) => {
        let params: IObj = {
          accessCode: this.accessCode,
          tenantModuleSet: '100015',
          ...commonParams,
        };
        if (this.tabKey === 'accountBind') {
          params = {
            ...params,
            username: data.wechatUsername,
            password: data.wechatPassword,
          };
        } else {
          params = {
            ...params,
            code: data.code,
            mobile: data.wechatUsername,
          };
        }
        request<IObj>({
          url,
          method: 'POST',
          data: params,
        })
          .then((res) => {
            if (res.data.canLogin) {
              window.location.assign('/egenie-ts-vogue/homePage/index');
            } else {
              if (this.tabKey === 'mobileBind') {
                this.getNeedBindWechatUserInfo();
              }
            }
          });
      });
  };

  @action private getNeedBindWechatUserInfo = () => {
    request<BaseData<INeedBindUser[]>>({
      url: api.getNeedBindWechatUserInfo,
      params: { accessCode: this.accessCode },
    })
      .then((res) => {
        this.bindInfo = res.data || [];
        this.selectUserId = this.bindInfo[0]?.userId;
        this.visible = true;
      });
  };

  @action public selectUser = (id: number): void => {
    this.selectUserId = id;
  };

  // 弹窗绑定
  @action public bindMobile = (): void => {
    request<BaseData<string>>({
      url: api.wechatChooseUser,
      method: 'POST',
      data: {
        ...commonParams,
        accessCode: this.accessCode,
        userId: this.selectUserId,
      },
    })
      .then((res) => {
        window.location.assign('/egenie-ts-vogue/homePage/index');
      });
  };

  // 验证图形验证码
  @action public showImageCode = (id: string, x: number, y: number): void => {
    const { wechatUsername } = this.loginRef.current.getFieldsValue();
    if (!wechatUsername) {
      message.error('请输入手机号');
      return;
    }
    if (!phoneReg.test(wechatUsername)) {
      message.error('请输入正确的手机号');
      return;
    }
    this.slideVerify?.clear();
    this.slideVerify = new SlideVerify({
      width: 240,
      height: 120,
      x,
      y,
      parentId: id,
      sliderText: '拖动滑块完成拼图，获取手机验证码',
      onSuccess: () => {
        // 发送验证码
        request({
          data: { mobile: wechatUsername },
          method: 'post',
          url: api.sendValidateCode,
        })
          .then(() => {
            this.isSendCode = true;
            this.countDown = 60;
            this.handleCountDown();
          });
      },
    });
  };

  // 倒计时
  @action public handleCountDown = (): void => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.countDown -= 1;
      if (this.countDown < 1) {
        clearInterval(this.timer);
        this.isSendCode = false;
      }
    }, 1000);
  };

  @action public toogleModal = (visible: boolean): void => {
    this.visible = visible;
  };
}

