import { Button, Form, Input, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../utils';
import { ProtalModal, TopBar } from '../components';
import { Footer } from '../components/footer';
import { FooterLogo } from '../components/footerLogo';
import { BIND_BTNS, TIP_TEXT } from './constant';
import styles from './index.less';
import { SelectUserModal } from './selectUserModal';
import { Store } from './store';

const { Item } = Form;

export const store = new Store();

const WechatLogin = observer(() => {
  const { userInfo } = store;
  return (
    <div className={styles.page}>
      <TopBar/>
      <div
        className={styles.bg}
      >
        <div className={styles.tip}>
          {TIP_TEXT}
        </div>
        <div className={styles.contentPart}>
          <div className={styles.wechatInfoWrap}>
            <img
              alt=""
              height={26}
              src={`${PUBLIC_IMAGE_URL}wechat.png`}
              width={26}
            />
            <img
              alt=""
              height={143}
              src={userInfo?.pic}
              style={{ margin: '20px 0' }}
              width={143}
            />
            <span className={styles.name}>
              {userInfo?.nickname}
            </span>
          </div>
          <div
            className={styles.bindTag}
          >
            <img
              alt=""
              height={40}
              src={`${PUBLIC_IMAGE_URL}fujian.png`}
              width={40}
            />
          </div>
          <div className={styles.loginWrapper}>
            <LoginContent store={store}/>
          </div>
        </div>
      </div>
      <FooterLogo/>
      <Footer/>
    </div>
  );
});

export const LoginContent: React.FC<{ store: Store; }> = observer((props) => {
  const {
    tabKey,
    isSendCode,
    showImageCode,
    countDown,
    changeTab,
    loginRef,
    bindWechat,
    protalModalStore,
  } = props.store;

  return (
    <div className={styles.login}>
      <img
        alt=""
        height={70}
        src={`${PUBLIC_IMAGE_URL}shjSimple.png`}
        width={140}
      />
      <div className={styles.tabWrap}>
        <Tabs
          activeKey={tabKey}
          items={BIND_BTNS}
          onChange={changeTab}
        />
      </div>
      <Form ref={loginRef}>
        <Item
          name="wechatUsername"
          rules={[
            {
              required: true,
              message: '请输入账号',
            },
          ]}
        >
          <Input
            placeholder={(tabKey === 'accountBind') ? '账号' : '手机号'}
            prefix={(
              <img
                className={styles.logIcon}
                src={`${PUBLIC_IMAGE_URL}account.png`}
              />
            )}
          />
        </Item>
        {(tabKey === 'accountBind') && (
          <Item
            name="wechatPassword"
            rules={[
              {
                required: true,
                message: '请输入密码',
              },
            ]}
          >
            <Input
              autoComplete="new-password"
              onPressEnter={bindWechat}
              placeholder="密码"
              prefix={(
                <img
                  className={styles.logIcon}
                  src={`${PUBLIC_IMAGE_URL}password.png`}
                />
              )}
              type="password"
            />
          </Item>
        )}
        {(tabKey === 'mobileBind') && (
          <div className={styles.validCodeWrap}>
            <Item
              name="code"
              rules={[
                {
                  required: true,
                  message: '请输入验证码',
                },
              ]}
            >
              <Input
                onPressEnter={bindWechat}
                placeholder="验证码"
                prefix={(
                  <img
                    className={styles.logIcon}
                    src={`${PUBLIC_IMAGE_URL}password.png`}
                  />
                )}
                style={{ width: '100%' }}
              />

            </Item>
            <Button
              className={styles.sendValidCode}
              disabled={isSendCode}
              onClick={showImageCode.bind(this, 'findPassword', 0, 0)}
            >
              {isSendCode ? `${countDown}S后重新获取` : '获取验证码'}
            </Button>
          </div>
        )}
      </Form>
      <div className={styles.loginTip}>
        <div
          className={styles.link}
          style={{ color: '#6D6E78' }}
        >
          确定绑定即视为同意
          <span
            className={styles.prototal}
            onClick={() => {
              protalModalStore.showModal(1, '衫海精用户协议');
            }}
          >
            《衫海精用户协议》
          </span>
          、
          <span
            className={styles.prototal}
            onClick={() => {
              protalModalStore.showModal(2, '隐私条款');
            }}
          >
            《隐私条款》
          </span>
        </div>
      </div>
      <Button
        className={styles.buttonStyle}
        danger
        onClick={bindWechat}
        type="primary"
      >
        立即绑定
      </Button>
      <SelectUserModal store={props.store}/>
      <ProtalModal store={protalModalStore}/>
    </div>
  );
});

export default WechatLogin;
