
export const BIND_BTNS = [
  {
    label: '手机号绑定',
    key: 'mobileBind',
  },
  {
    label: '帐号绑定',
    key: 'accountBind',
  },
];

export const TIP_TEXT = '为确保您账户的安全及正常使用，依《网络安全法》相关要求，8月1日起会员账户需绑定手机。如您还未绑定，请尽快完成，否则届时将无法登录，感谢您的理解及支持！';
